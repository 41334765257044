import React from "react";

import VideoPlayer from './video.js';
import "./App.css";




function App() {
  return (
    <div className="App">
       
    <header className="App-header">
     
    <img src="./kihoskh-bar-logo.png" alt="Logo" />
    </header>
     <div className="animation">
     <VideoPlayer />
    
     </div>
      
      <footer className="App-footer">
        <p>SØNDER BOULEVARD 52 1720 KØBENHAVN</p>
        <p>Opening Hours: Monday - Thursday:11-00  Friday: 11-02  Saturday: 11-02  Sunday: 11-22  No Reservations</p>
        <a href="https://www.findsmiley.dk/962630" className="footer-link" target="_blank" rel="noopener noreferrer"><img src="smiley.png" alt="Logo" /></a>

      </footer>
    </div>
  );
}
export default App;
